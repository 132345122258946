import React from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import {
  ButtonsSectionProps,
  ModalButtonsSection,
} from './ModalButtonsSection';
import { ModalCloseButton } from './ModalCloseButton';
import { ModalDetailsSection } from './ModalDetailsSection';

export * from './ModalButtonsSection';
export * from './ModalDetailsSection';
export * from './ModalTitle';

import { useCurrentTheme } from 'app/shared/theme';
import { useAnalyticsContext } from 'app/shared/utils';
import { useIsBreakpointSize } from 'app/shared/utils/useBreakpoint';
import {
  withSpacingCSS,
  WithSpacingProps,
} from 'app/shared/components/styleUtils';
import { Box } from 'app/shared/components/atoms';
import { usePortal } from 'app/fan/utils/usePortal';
import {
  PortalContainer,
  PortalTransitionProps,
  PortalUnderlay,
} from 'app/fan/components/atoms';

import { ModalTitle } from './ModalTitle';

export * from './ModalButtonsSection';
export * from './ModalTitle';

export interface ModalProps extends WithSpacingProps, ButtonsSectionProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  transitionSpeed?: number;
  title?: string;
  children?: React.ReactNode;
  detailText?: React.ReactNode | string;
  isFullscreenMobile?: boolean;
  maxWidth?: string;
  closeOnOutsideClick?: boolean;
  disableClose?: boolean;
  onClose?: () => void;
  portalRootId?: string;
  modalRef?: React.RefObject<HTMLDivElement>;
  'data-qaid': string;
}

interface ModalCSSProps extends PortalTransitionProps {
  isFullscreenMobile: boolean;
  maxWidth?: string;
}

const StyledDivModal = styled(Box)<ModalCSSProps>`
  ${({
    theme,
    isShowing,
    transitionSpeed,
    isFullscreenMobile,
    maxWidth,
  }) => css`
    opacity: 0%;
    transition: opacity ${transitionSpeed}ms ease;

    ${isShowing &&
      css`
        opacity: 100%;
      `}

    ${
      isFullscreenMobile
        ? css`
            position: fixed;
            left: 0;
            top: 0;
            margin: 0;
            width: 100vw;
            height: 100vh;
          `
        : css`
            position: relative;
            left: auto;
            top: auto;
            margin: 80px auto auto;
            height: auto;
            max-width: ${maxWidth};
            overflow: scroll;
          `
    }

    ${theme.media.tablet`
      left: auto;
      top: auto;
      position: relative;
      margin: 80px auto auto;
      height: auto;
      width: auto;
      ${maxWidth && `max-width: ${maxWidth}`}
      max-height: calc(100vh - 160px);
      overflow: scroll;
    `}
    ${withSpacingCSS};
  `}
`;

export const Modal = ({
  title,
  children,
  detailText,
  isOpen,
  setIsOpen,
  transitionSpeed = 300,
  maxWidth = '600px',
  isFullscreenMobile = false,
  closeOnOutsideClick = true,
  disableClose = false,
  primaryCtaText,
  onClickPrimaryCta,
  isPrimaryCtaFullWidth,
  secondaryCtaText,
  onClickSecondaryCta,
  SecondaryButtonComponent,
  secondaryButtonHref,
  onClose,
  portalRootId = 'sofar-portal-root',
  'data-qaid': dataQaid,
  modalRef,
  ...props
}: ModalProps) => {
  const buttonProps = {
    primaryCtaText,
    onClickPrimaryCta,
    isPrimaryCtaFullWidth,
    secondaryCtaText,
    onClickSecondaryCta,
    SecondaryButtonComponent,
    secondaryButtonHref,
  };

  const { boxShadow, colors, borderRadius, zIndex } = useCurrentTheme();
  const { isMobile } = useIsBreakpointSize();
  const { trackAnalyticsEvent } = useAnalyticsContext();

  function closeModal() {
    setIsOpen(false);
    trackAnalyticsEvent('Modal Closed By User', {
      modal: dataQaid,
      is_mobile: isMobile,
      title,
      primary_cta_text: primaryCtaText,
      secondary_cta_text: secondaryCtaText,
    });
    onClose && onClose();
  }
  const {
    portalRootRef: ModalPortalRootRef,
    isUnmounted,
    isShowing,
  } = usePortal({ isOpen, close: closeModal, transitionSpeed, portalRootId });

  if (isUnmounted) {
    return null;
  }

  const px = isFullscreenMobile ? [5, 8, 8, 8] : [4, 8, 8, 8];
  const pb = isFullscreenMobile ? [18, 8, 8, 8] : 12;

  const modalContents = (
    <Box flexDirection="column" pt={8} px={px} pb={pb} h="fit-content">
      <ModalCloseButton onClick={closeModal} disableClose={disableClose} />
      <Box flexDirection="column" gap={8}>
        <Box gap={4} flexDirection="column">
          {title && (
            <ModalTitle
              title={title}
              data-qaid={`${dataQaid}-title`}
              disableClose={disableClose}
            />
          )}
          <Box flexDirection="column" gap={3} data-qaid={`${dataQaid}-body`}>
            {children}
          </Box>
        </Box>
        <ModalButtonsSection
          {...buttonProps}
          primaryCtaDataQaid={`${dataQaid}-cta-primary`}
          secondaryCtaDataQaid={`${dataQaid}-cta-secondary`}
          onClickSecondaryCta={() => {
            buttonProps.onClickSecondaryCta &&
              buttonProps.onClickSecondaryCta();
            closeModal(); // Trigger 'Modal Closed By User' on secondary CTA click
          }}
        />
        <ModalDetailsSection
          detailText={detailText}
          data-qaid={`${dataQaid}-detail`}
        />
      </Box>
    </Box>
  );

  return createPortal(
    <PortalContainer
      aria-hidden={isOpen ? 'false' : 'true'}
      zIndex={zIndex.modal}
      data-qaid="modal"
    >
      <StyledDivModal
        ref={modalRef}
        data-qaid={dataQaid}
        maxWidth={maxWidth}
        isFullscreenMobile={isFullscreenMobile}
        isShowing={isShowing}
        transitionSpeed={transitionSpeed}
        zIndex={zIndex.modal}
        boxShadow={boxShadow.doubleShadow}
        backgroundColor={colors.primary.white.base}
        borderRadius={isFullscreenMobile && isMobile ? '0' : borderRadius.modal}
        overflow="scroll"
        {...props}
      >
        {modalContents}
      </StyledDivModal>
      <PortalUnderlay
        onClick={closeOnOutsideClick ? closeModal : () => {}}
        isShowing={isShowing}
        transitionSpeed={transitionSpeed}
      />
    </PortalContainer>,
    ModalPortalRootRef.current
  );
};
